import(/* webpackMode: "eager" */ "/home/eric/venice/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/eric/venice/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Dosis\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"600\",\"400\"]}],\"variableName\":\"dosis\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Header","Footer"] */ "/home/eric/venice/frontend/src/components/HeaderFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TopicProvider"] */ "/home/eric/venice/frontend/src/context/TopicContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserInfoProvider"] */ "/home/eric/venice/frontend/src/context/UserInfoContext.tsx");
;
import(/* webpackMode: "eager" */ "/home/eric/venice/frontend/src/styles/variables.css");
;
import(/* webpackMode: "eager" */ "/home/eric/venice/frontend/src/styles/globals.css");
