'use client'

import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { API_BASE_URL } from '../../lib/common'
import Link from 'next/link'
import { useUserInfo } from '@/context/UserInfoContext'

enum LoginState {
  // On page load, no session established
  NotLoggedIn,

  // Sent credentials to backend, waiting for response
  // validating those credentials
  SendingCredentials,

  // Credentials validated, waiting for session to be created
  // on the backend and for auth requests to succeed
  WaitingForSession,

  // Backend is returning that the user cookie is valid
  SessionEstablished,

  // We've requested a redirect
  Redirecting,
}

interface LoginFormProps {
  alreadyLoggedIn: boolean
}

// TODO(1): we are still getting failure-to-redirect sometimes.
const LoginForm: React.FC<LoginFormProps> = ({ alreadyLoggedIn }) => {
  const auth_check_url = `${API_BASE_URL}/auth/check`

  const router = useRouter()
  const [loginState, setLoginState] = useState<LoginState>(LoginState.NotLoggedIn)
  const [loginErrorMsg, setLoginErrorMsg] = useState<string>('')

  const { userInfo, reloadUserInfo } = useUserInfo()

  // Announce login state changes to screen readers
  // TODO(2): Not clear if this really should be done, we don't announce the login
  // state to non-screen-reader users...
  useEffect(() => {
    const message =
      loginState === LoginState.NotLoggedIn ? 'Login form ready' :
        loginState === LoginState.SendingCredentials ? 'Sending login credentials' :
          loginState === LoginState.WaitingForSession ? 'Waiting for session' :
            loginState === LoginState.SessionEstablished ? 'Session established' :
              loginState === LoginState.Redirecting ? 'Redirecting to home page' :
                'Unknown login state';

    const announcer = document.createElement('div');
    announcer.setAttribute('aria-live', 'polite');
    announcer.setAttribute('aria-atomic', 'true');
    announcer.classList.add('sr-only');
    announcer.textContent = message;
    document.body.appendChild(announcer);

    return () => {
      document.body.removeChild(announcer);
    };
  }, [loginState]);

  // Poll for session validation
  const [pollInterval, setPollInterval] = useState<number>(200)  // Start value
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null
    if (loginState === LoginState.WaitingForSession) {
      intervalId = setInterval(async () => {
        try {
          const res = await fetch(auth_check_url, { credentials: 'include' })
          if (res.ok && (await res.json()).valid) {
            setLoginState(LoginState.SessionEstablished)
          } else {
            setPollInterval((prev) => Math.min(prev * 1.6, 5000))
          }
        } catch (error) {
          setPollInterval((prev) => Math.min(prev * 1.6, 5000))
        }
      }, pollInterval)  // Every this many ms
    }

    // Cleanup
    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [loginState, auth_check_url, pollInterval])

  // Get redirect path from URL parameters
  const searchParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');
  const redirectPath = searchParams.get('redirect');

  // First effect: reload user info after session is established
  useEffect(() => {
    if (loginState === LoginState.SessionEstablished) {
      console.log("Session established, reloading user info")
      reloadUserInfo();
    }
  }, [loginState, reloadUserInfo]);

  // Second effect: handle redirect once we have user info.  We show a welcome
  // page if the user hasn't seen it yet, except that if the user requested a
  // specific redirect, we do that instead (this should be rare, and unharmful
  // since the user has a chance to see it next time they log in -- although it
  // might be a bit confusing).
  useEffect(() => {
    if (loginState === LoginState.SessionEstablished && userInfo) {
      console.log("Have user info, redirecting", userInfo)
      setLoginState(LoginState.Redirecting)
      if (redirectPath) {
        router.push(redirectPath);
      } else if (!userInfo.has_been_welcomed) {
        router.push('/welcome')
      } else {
        router.push('/')
      }
      router.refresh()
    }
  }, [loginState, userInfo, redirectPath, router]);

  const handleLoginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginState(LoginState.SendingCredentials);
    setLoginErrorMsg('');

    const formData = new FormData(event.currentTarget);

    try {
      const res = await fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          username: formData.get('username'),
          password: formData.get('password'),
        }),
      })

      if (res.ok) {
        // Do nothing here; a useEffect will redirect us to the home page after
        // the backend is able to validate the cookie.
        setLoginState(LoginState.WaitingForSession)
      } else {
        console.log('auth request fail', res)
        const errorData = await res.json()
        setLoginErrorMsg(errorData.message || 'Login failed. Please try again.')
        setLoginState(LoginState.NotLoggedIn)
      }
    } catch (error) {
      console.log('auth request error', error)
      setLoginErrorMsg('An error occurred. Please try again.')
      setLoginState(LoginState.NotLoggedIn)
    }
  }

  if (alreadyLoggedIn) {
    return (
      <section>
        <h2>You are already logged in!</h2>
        <p><Link href="/">Go to home page</Link></p>
        {/* TODO(2): logout button */}
      </section>
    )
  }

  return (
    // method="POST" shouldn't be required for a javascript handler, but we've
    // seen credentials occasionally appear in the URL bar (usually when there's
    // some backend problem) so we added this to see if it helps.
    <form method="POST" onSubmit={handleLoginSubmit} aria-label="Login form" className="login-form">
      <div className="form-group">
        <label htmlFor="username">Username:</label>
        <input type="text" id="username" name="username" required aria-required="true" />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password:</label>
        <input type="password" id="password" name="password" required aria-required="true" />
      </div>
      <div className="submit-group">
        <button
          type="submit"
          disabled={loginState !== LoginState.NotLoggedIn}
          aria-disabled={loginState !== LoginState.NotLoggedIn}
        >
          Submit
        </button>
      </div>
      <div aria-live="polite" aria-atomic="true">
        {loginState === LoginState.NotLoggedIn ? null :
          loginState === LoginState.SendingCredentials ? 'Logging in...' :
            loginState === LoginState.WaitingForSession ? 'Redirecting...' :
              loginState === LoginState.SessionEstablished ? 'You are already logged in!' :
                loginState === LoginState.Redirecting ? 'Redirecting...' :
                  'unknown login state'}
      </div>
      {loginErrorMsg && <p role="alert" style={{ color: 'red' }}>{loginErrorMsg}</p>}
    </form>
  )
}

const Home: React.FC = () => {
  const alreadyLoggedIn = false  // TODO(1): check if already logged in

  return (
    <main>
      <section className="flex-container" aria-labelledby="login-heading">
        <article className="flex-item content">
          <h1 id="login-heading" tabIndex={-1}>Login</h1>
          <LoginForm alreadyLoggedIn={alreadyLoggedIn} />
        </article>
      </section>
    </main>
  )
}

export default Home
