import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import { ComposeBox, ComposeBoxHandle } from './ComposeBox';
import { useRouter } from 'next/navigation';
import { FaTimes } from 'react-icons/fa';
import { VisibilitySelector } from './Visibility';
import { DisplayConceptRef } from '@/lib/api';
import { API_BASE_URL } from '@/lib/common';
import '@/styles/omnisearchbox.css';

/*

OmniSearchBox is a bit of a mashup of a TopicSelector and a ComposeBox. It's a
search box that allows you to search for concepts or to post a question.  It
does rely on ComposeBox, but right now TopicSelector is still independent
(and hence arguably redundant) code.

Things are slightly different in each context where one of these three components
are used, so it's not obvious how much is sharable.  The sharing between
ComposeBox and OmniSearchBox is already a little awkward.  With time and more
evolution, maybe these can be combined.

*/

interface OmniSearchBoxProps {
  onClose: () => void;
  example_topics?: DisplayConceptRef[];
  example_questions?: string[];
}

export const OmniSearchBox: React.FC<OmniSearchBoxProps> = (props) => {
  const router = useRouter();
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<DisplayConceptRef[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibility, setVisibility] = useState<'Public' | 'Friends' | 'Private'>('Public');
  const composeBoxRef = useRef<ComposeBoxHandle>(null);
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    // Focus the compose box when the component mounts
    composeBoxRef.current?.focus();
  }, []);

  useLayoutEffect(() => {
    const navbar = document.querySelector('.page-header');
    if (navbar) {
      const height = navbar.getBoundingClientRect().height;
      setNavbarHeight(height);
    }
  }, []);
  const [showVisibilityDropdown, setShowVisibilityDropdown] = useState(false);

  const searchConcepts = useCallback(async (query: string) => {
    if (!query) {
      setSearchResults([]);
      return;
    }
    console.log('Searching for:', query);

    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/schema/prefix_search/${query}`);
      if (!response.ok) {
        throw new Error('Search failed');
      }
      const data: DisplayConceptRef[] = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching concepts:', error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Debounce search input
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      searchConcepts(searchText);
    }, 300);

    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('keydown', handleEscape);
    };
  }, [searchText, searchConcepts, props]);

  return (
    <div
      className="omnisearch-overlay"
      onClick={props.onClose}
      style={{ '--navbar-height': `${navbarHeight}px` } as React.CSSProperties}
    >
      <div
        className="omnisearch-modal"
        onClick={e => e.stopPropagation()}
      >
        <div className="omnisearch-container">
          <div className="omnisearch-input-row">
            <ComposeBox
              ref={composeBoxRef}
              onClose={props.onClose}
              topic=""
              placeholder="Search or ask ..."
              content={searchText}
              onContentChange={setSearchText}
              className="omnisearch"
              show_hints={false}
              show_controls={false}
            />
            <button
              className="omnisearch-close"
              onClick={props.onClose}
              aria-label="Close search"
            >
              <FaTimes />
            </button>
          </div>
          <div className="omnisearch-results" role="listbox">
            {isLoading ? (
              <div className="omnisearch-message">Searching...</div>
            ) : !searchText ? (
              <div className="omnisearch-message">
                {/* <h2>Find answers</h2> */}
                <h3>Search products</h3>
                <ul className="omnisearch-examples">
                  {props.example_topics?.map((topic, index) => (
                    <li
                      key={index}
                      className="omnisearch-result"
                      onClick={() => setSearchText(topic.display)}
                      tabIndex={0}
                    >
                      &ldquo;{topic.display}&rdquo;
                    </li>
                  ))}
                </ul>
                <h3>Or ask a question</h3>
                <ul className="omnisearch-examples">
                  {props.example_questions?.map((question, index) => (
                    <li
                      key={index}
                      className="omnisearch-result"
                      onClick={() => setSearchText(question)}
                      tabIndex={0}
                    >
                      &ldquo;{question}&rdquo;
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <>
                {searchResults.map((result, index) => (
                  <div
                    key={index}
                    className="omnisearch-result"
                    onClick={() => {
                      props.onClose();
                      router.push(`/guide/${encodeURIComponent(result.cref)}`);
                    }}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        // props.onClose();
                        router.push(`/guide/${encodeURIComponent(result.cref)}`);
                      }
                    }}
                  >
                    <span className="omnisearch-result-name">
                      {result.display}:{' '}
                    </span>
                    <span className="omnisearch-result-description">
                      {result.overview}
                    </span>
                  </div>
                ))}
                {searchResults.length < 10 && (
                  <div className={`omnisearch-result post-question ${searchResults.length > 0 ? 'has-results' : ''}`}>
                    <span>Post this question to AI, friends, or the community </span>
                    <div className="post-controls">
                      <VisibilitySelector
                        visibility={visibility}
                        setVisibility={setVisibility}
                        showDropdown={showVisibilityDropdown}
                        setShowDropdown={setShowVisibilityDropdown}
                        position="below"
                      />
                      <button
                        onClick={() => {
                          // props.onClose();
                          composeBoxRef.current?.submit();
                        }}
                      >
                        Post
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
