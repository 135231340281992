//////////////////////////////////////////////////////////////////////////////
//
// Shared functions and components
//
// TODO(1): split this up into separate files

"use client"  /* TODO(1): figure out what can be server-side */

import React, { useState, useMemo, useId } from "react";
import Link from 'next/link';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { DisplayConceptRef, DisplayUserFollow, GuideJsonResp, DisplayUser, updateFollows } from '@/lib/api';
import ReactMarkdown from 'react-markdown';

// Using proxying for now for both normal API requests as well as websockets.
// This takes some configuration to set up.
export const API_BASE_URL = "/api";
export const WS_API_BASE_URL = "/wsapi";


//////////////////////////////////////////////////////////////////////////////
//
// General purpose helper functions
//

export const topic_url = (cref: string): string => `/guide/${encodeURIComponent(cref)}`;

export const sku_url = (cref: string): string => `/products/${encodeURIComponent(cref)}`;

export const strip_en = (s: string): string => s.replace("@en:", "");

// TODO(1): this should be more principled...., also consolidate with
// deployparams::is_llm_name() on the rust side.
export const isLLM = (userId: string): boolean => {
  const llmIds = ["gpt-4o", "mixtral8x7B", "llama31-70B"];
  return llmIds.includes(userId);
};


//////////////////////////////////////////////////////////////////////////////
//
// Simple shared or general purpose components
//

interface UserLinkProps {
  username: string;
}

export const UserLink: React.FC<UserLinkProps> = ({ username }) => (
  isLLM(username) ?
    <span aria-label={`AI model ${username}`}>{username}</span> :
    <Link href={`/citizen/${encodeURIComponent(username)}`} aria-label={`View profile of ${username}`}>{username}</Link>
);

// TODO(1): Consolidate
interface ConceptDef {
  sup?: string[];
  sub?: string[];
}

interface ExploreLinksProps {
  cdef: ConceptDef;
}

export const ExploreLinks: React.FC<ExploreLinksProps> = ({ cdef }) => (
  <nav className="content explore-links" aria-label="Concept hierarchy">
    {cdef.sup && cdef.sup.length > 0 && (
      <div className="explore-section">
        <h3 id="broader-concepts">Broader concepts:</h3>
        <ul className="no-bullets" aria-labelledby="broader-concepts">
          {/* TODO(3): taking the arbitrary first n; should score */}
          {cdef.sup.slice(0, 5).map((sup) => (
            <li key={sup}>
              <Link href={topic_url(sup)} aria-label={`Broader concept: ${strip_en(sup)}`}>{strip_en(sup)}</Link>
            </li>
          ))}
        </ul>
      </div>
    )}
    {cdef.sub && cdef.sub.length > 0 && (
      <div className="explore-section">
        <h3 id="subcategories">Subcategories:</h3>
        <ul className="no-bullets" aria-labelledby="subcategories">
          {/* TODO(3): taking the arbitrary first n; should score */}
          {cdef.sub.slice(0, 20).map((sub) => (
            <li key={sub}>
              <Link href={topic_url(sub)} aria-label={`Subcategory: ${strip_en(sub)}`}>{strip_en(sub)}</Link>
            </li>
          ))}
        </ul>
      </div>
    )}
  </nav>
);

interface GuideSummaryContentProps {
  guide: GuideJsonResp;
}

export const GuideSummaryContent: React.FC<GuideSummaryContentProps> = ({ guide }) => (
  <div>
    <ReactMarkdown>{guide?.summary ?? ""}</ReactMarkdown>
  </div>
);

//////////////////////////////////////////////////////////////////////////////
//
// A general purpose container for content
//

interface ContentCardProps {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  collapsible: boolean;
  initialState?: boolean;
  className?: string;
}

export const ContentCard: React.FC<ContentCardProps> = ({ title, children, collapsible, initialState = true, className }) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const contentId = useId();

  const content = useMemo(() => {
    if (collapsible) {
      const childArray = React.Children.toArray(children);
      if (childArray.length !== 2) {
        console.warn('ContentCard: Collapsible content should have exactly two children');
        return null;
      }
      return isOpen ? childArray[1] : childArray[0];
    }
    return children;
  }, [collapsible, children, isOpen]);

  return (
    <article className={`content ${className || ''}`}>
      {title && <h3 className="content-title">{title}</h3>}
      <div className="content-wrapper">
        <div
          id={contentId}
          className={`collapsible__content ${isOpen ? 'collapsible__content--open' : ''}`}
          aria-hidden={collapsible && !isOpen}
        >
          {content}
        </div>
        {collapsible && (
          <div className="toggle-wrapper">
            <button
              className="collapsible__toggle"
              onClick={() => setIsOpen((prev) => !prev)}
              aria-expanded={isOpen}
              aria-controls={contentId}
              aria-label={isOpen ? "Collapse content" : "Expand content"}
            >
              <span className="sr-only">{isOpen ? "Collapse" : "Expand"}</span>
              {isOpen ? <FaChevronUp aria-hidden="true" /> : <FaChevronDown aria-hidden="true" />}
            </button>
          </div>
        )}
      </div>
    </article>
  );
};

interface ToggleContainerProps {
  title?: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const ToggleContainer: React.FC<ToggleContainerProps> = ({ title, children, onClose }) => (
  <div className="toggle-container">
    <div className="toggle-container-content">
      {title && <h2>{title}</h2>}
      {children}
    </div>
  </div>
);

//////////////////////////////////////////////////////////////////////////////
//
// Functions for accessing and updating info about the current logged-in user
// from the backend
// 

export const followsMap = (follows: DisplayUserFollow[] | null): Map<string, DisplayConceptRef[]> => {
  const followsMap = new Map<string, DisplayConceptRef[]>();
  if (follows != null) {
    follows.forEach((follow) => {
      followsMap.set(follow.username, follow.topics);
    });
  }
  return followsMap;
};

export const followTopic = async (username: string, topic: DisplayConceptRef): Promise<DisplayUser> => {
  return updateFollows(username, { 
    action: "Follow",
    topics: [topic.cref]
  });
};

export const unfollowTopic = async (username: string, topic: DisplayConceptRef): Promise<DisplayUser> => {
  return updateFollows(username, {
    action: "Unfollow", 
    topics: [topic.cref]
  });
};

// TODO(2): should the caller pass in all this info, or just the username, and let
// the component do the loading?  Or, should we pass in a structured follow object?
interface FollowButtonProps {
  authUsername: string;
  followUsername: string;
  currentlyFollowing: boolean;
  topics: DisplayConceptRef[];
  onFollowUpdate?: (updatedUserInfo: DisplayUser) => void;
}

export const renderTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);
  const now = new Date();

  if (date.toDateString() === now.toDateString()) {
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds}s`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)}m`;
    } else {
      return `${Math.floor(diffInSeconds / 3600)}h`;
    }
  }

  if (date.getFullYear() === now.getFullYear()) {
    return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
  }

  return date.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' });
};

interface JoinButtonProps {
  authUsername: string;
  topic: DisplayConceptRef;
  currentlyJoined: boolean;
  onJoinUpdate?: (updatedUserInfo: DisplayUser) => void;
}

// TODO(2): integrate this with the user Follow mechanism and button
export const JoinButton: React.FC<JoinButtonProps> = ({ authUsername, topic, currentlyJoined, onJoinUpdate }) => {
  const handleJoin = async () => {
    try {
      const updatedUserInfo: DisplayUser = currentlyJoined
        ? await unfollowTopic(authUsername, topic)
        : await followTopic(authUsername, topic);
      if (onJoinUpdate) {
        onJoinUpdate(updatedUserInfo);
      }
    } catch (error) {
      console.error('Error following topic:', error);
    }
  };

  return (
    <button onClick={handleJoin}>
      {currentlyJoined ? `Unfollow` : `Follow`}
    </button>
  );
};
