'use client';

import React, { useState, useEffect, useRef, useCallback } from "react";
import Link from 'next/link';
import { FaSearch, FaBars, FaPlus, FaRegCommentAlt, FaMagic, FaRegStar, FaStar } from 'react-icons/fa';
import { useParams, useRouter, useSelectedLayoutSegment } from "next/navigation"
import { useTopicContext } from '@context/TopicContext';
import { useUserInfo } from '@/context/UserInfoContext';
import { API_BASE_URL, WS_API_BASE_URL } from '@/lib/common';
import { useState as useModalState } from 'react';

// Only needed for Header<>, keep with if you factor those components out
import '@/styles/headerfooter.css';
import '@/styles/topicselector.css'
import { OmniSearchBox } from "./OmniSearchBox";

enum Buttons {
    Home = "Home",
    Guide = "Guide",
    Talk = "Talk",
    Shop = "Shop",
    Unknown = "Unknown",
}

const Header: React.FC = () => {
    const [menuExpanded, setMenuExpanded] = useState(false);
    const segment = useSelectedLayoutSegment();
    const params = useParams();
    const { topic, setTopic } = useTopicContext();
    const [activeButton, setActiveButton] = useState<Buttons>(Buttons.Unknown);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const topicSelectorRef = useRef<HTMLDivElement>(null);
    const searchContainerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLLIElement>(null);

    const url_arg = topic ? '/' + encodeURIComponent(topic.cref) : '';

    const { userInfo } = useUserInfo();

    useEffect(() => {
        if (isSearching && topicSelectorRef.current) {
            const input = topicSelectorRef.current.querySelector('input');
            if (input) {
                input.focus();
            }
        }
    }, [isSearching]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
                setIsSearching(false);
            }
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
                setMenuExpanded(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        function segmentToButton(segment: string | null): Buttons {
            switch (segment) {
                case null:
                    return Buttons.Home;
                case "guide":
                    return Buttons.Guide;
                case "docs":
                case "doc":
                    return Buttons.Talk;
                case "topicproducts":
                case "products":
                    return Buttons.Shop;
                default:
                    return Buttons.Unknown;
            }
        }

        const newActiveButton = segmentToButton(segment);
        setActiveButton(newActiveButton);
        if (params && params.id) {
            /*
            
            Currently the topic is set by individual pages in the TopicContext.  An
            alternative would be to look at the id component here and turn that into
            a CDef to render.  That would require an extra call to the server,
            though; also that call is hard to do inside a useEffect.  So for now we
            set button status by looking at the router segment here, but set topic
            by a callback via context.
    
            // This is a dumb assumption, that if we have an ID component that looks
            // like a cref, then it's the topic.  It would be better to have some
            // centralized place to define routes and paths and their meaning. 
            const maybe_cref = decodeURIComponent(
                Array.isArray(params.id) ?  params.id[0] : params.id);
            if (maybe_cref.startsWith('@') || maybe_cref.startsWith('/')) {
              const cref = maybe_cref;
              // Need to look up cdef for this cref, construct a DisplayConceptRef
              // out of it, and then set it as the topic here in the header.
            }
    
            */
        }
    }, [segment, params]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setMenuExpanded(!menuExpanded);
    };

    const toggleSearch = () => {
        // Always toggle search when clicking on the topic title
        setIsSearching(!isSearching);
    };

    const router = useRouter();

    return (
        <header className="page-header" role="banner">
            {userInfo ? (
                // logged in headers
                <>
                    <nav className="top-nav" role="navigation">
                        <span className="title">
                            <Link 
                                href="/" 
                                className="header-logo-link"
                                title="Home: your feed"
                                aria-label="Home: your feed"
                            >
                                <h1 className="header-logo">metamynd</h1>
                            </Link>
                        </span>

                        <span className="buttonbar">
                            <ul className="buttonlist">
                                <li>
                                    <button
                                        onClick={() => setIsSearching(true)}
                                        className="header-icon-button"
                                        aria-label="Find or ask"
                                        title="Find or ask"
                                    >
                                        <FaSearch />
                                        {/* <FaPlus /> */}
                                        {/* <FaMessage /> */}
                                        {/* <FaRegCommentAlt /> */}
                                    </button>
                                    {isSearching && (
                                        // TODO(1): make these personalized
                                        <OmniSearchBox
                                            onClose={() => setIsSearching(false)}
                                            example_topics={[
                                                /* TODO(1): these aren't really dcrefs and the overview and cref
                                                   aren't even used.... */
                                                {
                                                    cref: '@en:juicer', display: 'juicer',
                                                    overview: 'A toaster is a small kitchen appliance designed to toast multiple types of bread products.'
                                                },
                                                {
                                                    cref: '@en:gaming tv', display: 'gaming tv',
                                                    overview: 'An armchair is a comfortable chair with side supports for the arms.'
                                                },
                                                {
                                                    cref: '@en:ski gear', display: 'ski gear',
                                                    overview: 'A television (TV) is a device used to display moving images and sound.'
                                                }
                                            ]}
                                            example_questions={[
                                                "what's the best toaster under $50?",
                                                "how do I choose a comfortable office chair?",
                                                "i'm looking for something to play music from streaming services, but with a built in amp",
                                            ]}
                                        />
                                    )}
                                </li>
                                <li>
                                    <Link 
                                        href="/collections" 
                                        className="header-icon-button"
                                        aria-label="Your starred items"
                                        title="Your starred items"
                                    >
                                        <FaStar />
                                    </Link>
                                </li>
 
                                {/* <li><Link href="/" className={activeButton === Buttons.Home ? 'active' : ''} aria-current={activeButton === Buttons.Home ? 'page' : undefined}>Home</Link></li>
                                <li><Link href={`/guide${url_arg}`} className={activeButton === Buttons.Guide ? 'active' : ''} aria-current={activeButton === Buttons.Guide ? 'page' : undefined}>Guide</Link></li>
                                <li><Link href={`/docs${url_arg}`} className={activeButton === Buttons.Talk ? 'active' : ''} aria-current={activeButton === Buttons.Talk ? 'page' : undefined}>Talk</Link></li>
                                <li><Link href={`/topicproducts${url_arg}`} className={activeButton === Buttons.Shop ? 'active' : ''} aria-current={activeButton === Buttons.Shop ? 'page' : undefined}>Shop</Link></li> */}

                                <li ref={menuRef}>
                                    <button
                                        onClick={toggleMenu}
                                        className="header-icon-button"
                                        aria-expanded={menuExpanded}
                                        aria-haspopup="true"
                                        aria-label="More options"
                                        title="More options"
                                    >
                                        <FaBars />
                                    </button>
                                    {isMenuOpen && (
                                        <nav className="popup-menu" aria-label="Additional navigation menu">
                                            <Link href="/account" onClick={toggleMenu}>Account</Link>
                                            {/* Moved to admin section <Link href="/citizens" onClick={toggleMenu}>Citizens</Link> */}
                                            {userInfo?.may_invite && (
                                              <Link href="/invite" onClick={toggleMenu}>Invite</Link>
                                            )}
                                            <Link href={`/admin/schema/${encodeURIComponent(topic?.cref || '')}`} onClick={toggleMenu}>Debug</Link>
                                            <Link href="/help" onClick={toggleMenu}>Help</Link>
                                            <Link href="/logout" onClick={toggleMenu}>Logout</Link>
                                        </nav>
                                    )}
                                </li>
                            </ul>
                        </span>
                    </nav>
                </>
            ) : (
                // logged out headers
                <nav className="top-nav">
                    <div className="title">
                        <h1>invite only</h1>
                    </div>
                    <div className="buttonbar">
                        <ul className="buttonlist">
                            <li><Link href="/login">Login</Link></li>
                            <li><Link href="/register">Register</Link></li>
                        </ul>
                    </div>
                </nav>
            )}
        </header>
    );
}

const Footer: React.FC = () => {
    const { userInfo } = useUserInfo();
    return (
        <footer className="page-footer" role="contentinfo">
            {userInfo && userInfo.username && (
                <>
                    <span className="logo-font">© 2024 metamynd</span>
                    <span className="logo-font"> Logged in as: <span aria-label="Current user">{userInfo.username}</span></span>
                </>
            )}
        </footer>
    );
}

export { Header, Footer };
